import { TimeLocaleDefinition } from 'd3-time-format';

export const DEFAULT_D3_TIME_FORMAT: TimeLocaleDefinition = {
  dateTime: '%x, %X',
  date: '%-d/%-m/%Y',
  time: '%-H:%M:%S',
  periods: ['AM', 'PM'],
  days: [
    'Воскресенье',
    'Понедельник',
    'Вторник',
    'Среда',
    'Четверг',
    'Пятница',
    'Суббота',
  ],
  shortDays: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
  months: [
    'Январь',
    'Февраль',
    'Март',
    'Апрель',
    'Май',
    'Июнь',
    'Июль',
    'Август',
    'Сентябрь',
    'Октябрь',
    'Ноябрь',
    'Декабрь',
  ],
  shortMonths: [
    'Янв',
    'Фев',
    'Мар',
    'Апр',
    'Май',
    'Июн',
    'Июл',
    'Авг',
    'Сен',
    'Окт',
    'Ноя',
    'Дек',
  ],
};
