/*
 * Licensed to the Apache Software Foundation (ASF) under one
 * or more contributor license agreements.  See the NOTICE file
 * distributed with this work for additional information
 * regarding copyright ownership.  The ASF licenses this file
 * to you under the Apache License, Version 2.0 (the
 * "License"); you may not use this file except in compliance
 * with the License.  You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */

import CategoricalScheme from '../../CategoricalScheme';

const schemes = [
  {
    id: 'auditColors',
    label: 'Audit Colors',
    colors: [
      '#00f0f0', // Яркий бирюзовый (очень светлый, насыщенный холодный)
      '#00D2D2', // Бирюзовый (насыщенный холодный)
      '#00BABA', // Тёмно-бирюзовый (насыщенный холодный)
      '#4DD096', // Светлый мятный (менее насыщенный холодный)
      '#95F7B1', // Светло-зелёный (холодный с оттенком тепла)
      '#00A579', // Тёмный зелёный (менее насыщенный холодный)
      '#005557', // Тёмный зелёно-синий (насыщенный холодный)
      '#d2f88a', // Светло-зелёно-жёлтый (переходный цвет от холодного к тёплому)
      '#eee8a9', // Светло-жёлтый (тёплый, менее насыщенный)
      '#d99c39', // Жёлто-оранжевый (тёплый, средне насыщенный)
      '#ff8749', // Оранжевый (насыщенный тёплый)
      '#a93800', // Тёмно-оранжевый/красный (тёплый, насыщенный)
    ],
  },
].map(s => new CategoricalScheme(s));

export default schemes;
